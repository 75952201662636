<template>
  <div class="not-found">
    <v-img src="../../assets/notFound.jpg" width="500"></v-img>

    <p>The page you're looking for doesn't exist.</p>
    <v-btn class="text-capitalize mt-4 rounded-lg" size="large" color="primary" to="/"
      ><v-icon left class="mr-2">mdi-home-outline</v-icon> Go Home
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
</style>
