import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/MainHomePages/LoginPage.vue";
import { getCurrentUser } from "aws-amplify/auth";
import NotFound from "../views/MainHomePages/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: HomeView,
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("../views/MainHomePages/RegistrationPage.vue"),
    meta: {
      title: "Registration | Call Central",
    },
  },
  {
    path: "/landingpage",
    name: "landingpage",
    component: () => import("../views/MainHomePages/LandingPage.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/ChildHomePages/Dashboard.vue"),
        meta: {
          title: "Dashboard | Call Central",
          requiresAuth: true,
        },
      },
      {
        path: "/call-logs",
        name: "call-logs",
        component: () => import("../views/ChildHomePages/CallLogs.vue"),
        meta: {
          title: "Call Logs | Call Central",
          requiresAuth: true,
        },
      },
      {
        path: "/admins",
        name: "admins",
        component: () => import("../views/ChildHomePages/AdminUses.vue"),
        meta: {
          title: "Admins | Call Central",
          requiresAuth: true,
        },
      },
      {
        path: "/callagents",
        name: "callagents",
        component: () => import("../views/ChildHomePages/CallAgents.vue"),
        meta: {
          title: "Call Agents | Call Central",
          requiresAuth: true,
        },
      },
      {
        path: "/products",
        name: "products",
        component: () => import("../views/ChildHomePages/ProductsPage.vue"),
        meta: {
          title: "Products | Call Central",
          requiresAuth: true,
        },
        children: [
          {
            path: ":product_id",
            name: "productdetails",
            component: () => import("@/components/Products/Cards/ProductDetailsCard.vue"),
            meta: {
              title: "Product | Call Central",
              requiresAuth: true,
            },
            props: true,
            children: [
              {
                path: "product-overview",
                name: "product-overview",
                component: () => import("@/components/Products/Cards/ProductInfo/Cards/ProductInformation.vue"),
                meta: {
                  title: "Product Overview | Call Central",
                  requiresAuth: true,
                },
                props: true,
              },
              {
                path: "dispositioncodes",
                name: "dispositioncodes",
                component: () => import("@/components/Products/Cards/DispositionCodes.vue"),
                meta: {
                  title: "Disposition Codes | Call Central",
                  requiresAuth: true,
                },
                props: true,
              },
              {
                path: "lead-data",
                name: "lead-data",
                component: () => import("@/components/Products/Cards/CRMData/Cards/CRMData.vue"),
                meta: {
                  title: "Lead Data | Call Central",
                  requiresAuth: true,
                },
                props: true,
                children: [
                  {
                    path: ":crm_history_id",
                    name: "leaddatalist",
                    component: () => import("@/components/Products/Cards/CRMData/Cards/CMRDataCard.vue"),
                    meta: {
                      title: "Lead Data List | Call Central",
                      requiresAuth: true,
                    },
                    props: true,
                    children: [
                      {
                        path: ":lead_id",
                        name: "lead-info",
                        component: () => import("@/components/Products/Cards/CRMData/Cards/LeadInfo.vue"),
                        meta: {
                          title: "Lead Info | Call Central",
                          requiresAuth: true,
                        },
                        props: true,
                      },
                    ],
                  },
                ],
              },
              {
                path: "product-call-logs",
                name: "product-call-logs",
                component: () => import("@/components/Products/Cards/ProductCallLogs.vue"),
                meta: {
                  title: "Product Call Logs | Call Central",
                  requiresAuth: true,
                },
                props: true,
              },
            ],
          },
        ],
      },

      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/ChildHomePages/SettingsPage.vue"),
        meta: {
          title: "Settings | Call Central",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/no-access",
    name: "no-access",
    component: () => import("../views/MainHomePages/NoAccess.vue"),
    meta: {
      title: "Access Denied",
    },
  },
  {
    path: "/kyc-pending", // This matches any unmatched routes
    name: "kycpendng",
    component: () => import("../views/MainHomePages/KYCPending.vue"), // Use the NotFound component
    meta: {
      title: "KYC Pending | Call Central",
    },
  },
  {
    path: "/:pathMatch(.*)*", // This matches any unmatched routes
    name: "notfound",
    component: NotFound, // Use the NotFound component
    meta: {
      title: "404 | Page Not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const defaultTitle = "Call Central";
  document.title = to.meta.title || defaultTitle;

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    try {
      const { signInDetails } = await getCurrentUser();

      if (signInDetails) next();
    } catch (error) {
      alert("You need to be logged in to access this page. Redirecting to login...");
      next({ name: "login", query: { redirect: to.fullPath, replace: true } });
    }
  } else {
    /* If no authentication is required, allow navigation */
    next();
  }
});

export default router;
