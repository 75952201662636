import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
import store from "./store";
import VueApexCharts from "vue3-apexcharts";

loadFonts();

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID,
      userPoolClientId: process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      signUpVerificationMethod: "code",
      loginWith: {
        email: true,
      },
    },
  },

  API: {
    GraphQL: {
      endpoint: process.env.VUE_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
      region: process.env.VUE_APP_AWS_REGION,
      defaultAuthMode: process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE,
      apiKey: process.env.VUE_APP_APPSYNC_API_KEY,
    },
  },
});
createApp(App).use(store).use(router).use(vuetify).use(VueApexCharts).mount("#app");
